import React from "react";

export interface SEOProps {
  title?: string;
  description?: string;
}

const SEO = ({
  title = "Psychotherapiepraxis Düsseldorf",
  description = "Wir sind eine Praxisgemeinschaft mit ärztlichen & psychologischen Therapeuten in der Benderstraße 76, 40625 Düsseldorf Düsseldorf.",
}) => {
  return (
    <>
      <html lang="de" />
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      ></meta>

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content="https://psychotherapiepraxis-duesseldorf.de"
      />
      <meta
        property="og:image"
        content="https://psychotherapiepraxis-duesseldorf.de/og-image.jpg"
      />
      <meta property="og:locale" content="de_DE" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:domain"
        content="psychotherapiepraxis-duesseldorf.de"
      />
      <meta
        property="twitter:url"
        content="https://www.psychotherapiepraxis-duesseldorf.de/"
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://psychotherapiepraxis-duesseldorf.de/og-image.jpg"
      />
    </>
  );
};

export default SEO;
