import { HeadFC } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import SEO from "../components/seo";

export interface ContentPageProps {
  children: React.ReactNode;
}

const ContentPage: React.FC<ContentPageProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
      <Breadcrumbs />
      <div className="prose prose-slate prose-headings:text-slate-700">
        {children}
      </div>
    </div>
  );
};

export const Head: HeadFC = () => <SEO />;

export default ContentPage;
