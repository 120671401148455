import { useLocation } from "@reach/router";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import CaretRight from "./icons/caret-right";

const Breadcrumbs: React.FC = ({ ...rest }) => {
  const location = useLocation();

  const {
    site: {
      siteMetadata: { pagesJSON },
    },
  } = useStaticQuery(query);

  const pagesData = React.useMemo(() => {
    return JSON.parse(pagesJSON);
  }, []);

  const paths = React.useMemo(() => {
    const parts = location.pathname.split("/").filter((v) => v !== "");
    const crumbs: string[] = [];

    if (parts === undefined) {
      return null;
    }

    let path = "";

    parts.forEach((part) => {
      path = `${path}/${part}`;
      crumbs.push(path);
    });

    return pagesData[crumbs[0]]
      ? crumbs.map((p) => ({
          link: p,
          name: pagesData[p].name,
        }))
      : null;
  }, [location.pathname, pagesData]);

  return (
    <div className="flex items-center gap-1" {...rest}>
      <Link className="text-sm text-red-accent" to="/">
        Startseite
      </Link>
      {paths &&
        paths.map((path, index) => (
          <React.Fragment key={path.link}>
            <CaretRight className="w-3 h-3 text-red-accent" />
            {index === paths.length - 1 ? (
              <span className="text-sm text-slate-500">{path.name}</span>
            ) : (
              <Link className="text-sm text-red-accent" to={path.link}>
                {path.name}
              </Link>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

const query = graphql`
  query BreadcrumbsQuery {
    site {
      siteMetadata {
        pagesJSON
      }
    }
    # allSitePages
  }
`;

export default Breadcrumbs;
